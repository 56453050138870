:root {
	/* Pallet 1 */
	--black-coral: #545F66;
	--cadet-grey: #829399;
	--light-cyan: #D0F4EA;
	--tea-green: #E8FCC2;
	--middle-green-yellow: #B1CC74;

	/* Pallet 2 */	
	--baby-blue-eyes: #A9CEF4;
	--charcoal: #36494E;
	--black: #000000;
	--air-superiority-blue: #7EA0B7;
	--dark-electric-blue: #597081;
	--silver-sand: #B7C3CD;

	--main-text: var(--black);
	--secondary-text: var(--charcoal);
	--bg-main: var(--baby-blue-eyes);
	--bg-secondary: var(--air-superiority-blue);
	--bg-tertiary: var(--silver-sand);
}

.App {
  text-align: center;
	color: var(--main-text);
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--bg-main);
  min-height: 5vh;
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: var(--main-text);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#resume {
	margin-bottom: 110px;
	font-size: 15px;
}
