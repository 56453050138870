.float {
	position:fixed;
	bottom:40px;
	right:40px;
}

.pdf-download-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.download-button {
	width:60px;
	height:60px;
	background-color: var(--bg-main);
	color:#FFF;
	border-radius:50px;
	border-width: 0px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.center-text {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pdf-tooltip .pdf-tooltiptext {
	visibility: hidden;
	z-index: 1;
	width: 120px;
 	top: -5px;
  right: 105%; 
	background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
	margin-right: 0.5em;
}

.pdf-download-container:hover .pdf-tooltiptext {
	visibility: visible;
}
