.section {
	text-align: left;
	align-items: center;
	justify-content: center;
	padding: 0vh 2vw 0;
}

.section h2 {
	background-color: var(--bg-tertiary);
}

/* Spacing fix so the PDF Creation does a proper line break*/
/* .section#experience div:nth-child(4) { */
/* 	margin-top: 1em; */
/* } */
.page-break div {
	padding-top: 6em;
}
