.career-entry {
	padding: 0.5vh 1vw;
	border: 1px solid;
	margin-top: 0.5em;
}

.career-entry div.career-entry-achievements li {
	margin-top: 0.5em;
	list-style: none;
}
